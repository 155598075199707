// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-current-state-of-death-js": () => import("./../../../src/pages/current-state-of-death.js" /* webpackChunkName: "component---src-pages-current-state-of-death-js" */),
  "component---src-pages-drivers-index-js": () => import("./../../../src/pages/drivers/index.js" /* webpackChunkName: "component---src-pages-drivers-index-js" */),
  "component---src-pages-futures-collapse-index-js": () => import("./../../../src/pages/futures/collapse/index.js" /* webpackChunkName: "component---src-pages-futures-collapse-index-js" */),
  "component---src-pages-futures-index-js": () => import("./../../../src/pages/futures/index.js" /* webpackChunkName: "component---src-pages-futures-index-js" */),
  "component---src-pages-futures-transformative-index-js": () => import("./../../../src/pages/futures/transformative/index.js" /* webpackChunkName: "component---src-pages-futures-transformative-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-options-educate-death-index-js": () => import("./../../../src/pages/options/educate-death/index.js" /* webpackChunkName: "component---src-pages-options-educate-death-index-js" */),
  "component---src-pages-options-index-js": () => import("./../../../src/pages/options/index.js" /* webpackChunkName: "component---src-pages-options-index-js" */),
  "component---src-pages-research-index-js": () => import("./../../../src/pages/research/index.js" /* webpackChunkName: "component---src-pages-research-index-js" */),
  "component---src-pages-research-interviews-index-js": () => import("./../../../src/pages/research/Interviews/index.js" /* webpackChunkName: "component---src-pages-research-interviews-index-js" */),
  "component---src-pages-research-trends-index-js": () => import("./../../../src/pages/research/trends/index.js" /* webpackChunkName: "component---src-pages-research-trends-index-js" */),
  "component---src-pages-scanning-hits-index-js": () => import("./../../../src/pages/scanning-hits/index.js" /* webpackChunkName: "component---src-pages-scanning-hits-index-js" */),
  "component---src-pages-scenarios-index-js": () => import("./../../../src/pages/scenarios/index.js" /* webpackChunkName: "component---src-pages-scenarios-index-js" */),
  "component---src-pages-table-of-contents-index-js": () => import("./../../../src/pages/table-of-contents/index.js" /* webpackChunkName: "component---src-pages-table-of-contents-index-js" */),
  "component---src-pages-timeline-index-js": () => import("./../../../src/pages/timeline/index.js" /* webpackChunkName: "component---src-pages-timeline-index-js" */),
  "component---src-pages-word-game-index-js": () => import("./../../../src/pages/word-game/index.js" /* webpackChunkName: "component---src-pages-word-game-index-js" */)
}

